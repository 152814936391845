<template>
  <div class="page-center text-left">
    <vca-card class="form-center">
      <vca-field>
        <form>
          <h3>{{ $t("profile.email.header") }}</h3>
          <p>{{ $t("profile.email.description") }}</p>
          <vca-input
            ref="email"
            name="email"
            type="email"
            :errorMsg="$t('sign.reset_email.email.error')"
            :placeholder="$t('sign.reset_email.email.placeholder')"
            v-model.trim="email"
            :rules="$v.email"
          >
          </vca-input>
          <button class="vca-button" @click.prevent="validate">
            {{ $t("sign.reset_email.title") }}
          </button>
        </form>
      </vca-field>
    </vca-card>
  </div>
</template>
<script>
export default {
  name: "ResetEmailForm",
  computed: {
    email: {
      get() {
        return this.$store.state.user.reset_email.email;
      },
      set(value) {
        this.$store.commit("user/reset_email", value);
      },
    },
  },
  validations() {
    return this.$store.getters["user/validation_email"];
  },
  methods: {
    validate() {
      if (this.$v.$invalid === true) {
        this.$refs.email.validate();
      } else {
        this.submit();
      }
    },
    submit() {
      this.$store.dispatch({ type: "user/email_start" }).then(() => {
        this.$emit("success");
      });
    },
  },
};
</script>
