<template>
  <div class="text-left">
    <vca-card>
      <ResetEmailForm @success="success" />
    </vca-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ResetEmailForm from "@/components/ResetEmailForm";
export default {
  name: "EmailEdit",
  components: {
    ResetEmailForm,
  },
  computed: {
    ...mapGetters({
      callback: "callback",
    }),
  },
  methods: {
    success() {
      var that = this;
      setTimeout(function () {
        if (that.callback) {
          window.location.href = window.atob(that.callback);
        } else {
          that.$router.push({ path: "/login" });
        }
      }, 6000);
    },
  },
};
</script>
